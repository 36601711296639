/*
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-31 10:37:11
 * @LastEditors: 沈为
 * @LastEditTime: 2022-08-29 21:23:26
 */
'use strict'
/**
 * 	appid : 		小程序appid
 *  baseUrl : 		服务端域名
 */
export default {
  appid: '****',
  //测试环境
  baseUrl: 'https://api.lanretni.bytepic.cn',
  //生产环境
  // baseUrl: 'https://api.bytepic.cn',
    user:{
        phone:'',
        name:''
    }
}
