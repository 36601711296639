/*
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-30 16:13:47
 * @LastEditors: 沈为
 * @LastEditTime: 2022-07-31 11:20:19
 */
import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router/index'
import 'ant-design-vue/dist/antd.css';
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App).use(router).use(Antd);
app.mount('#app');


app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)
