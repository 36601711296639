<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-30 16:13:47
 * @LastEditors: 沈为
 * @LastEditTime: 2022-07-31 10:44:38
-->
<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
